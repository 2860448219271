import React, { useState } from "react"
import { slide as Menu } from "react-burger-menu"
import ProductDescription from "./ProductDescription"
import Attributes from "./Attributes"
import StoreLocations from "./StoreLocations"
import { getTranslatableField } from "../../helpers/utils"

export default function BurgerOpener({
  appProps, product, showDescription, setShowDescription, showAttributes, setShowAttributes,
  showStoreLocations, setShowStoreLocations, menuOpen, setMenuOpen, category
}) {
  const productDescription = getTranslatableField(product, 'description', appProps.currentLocale, false)

  function handleStateChange(state) {
    setMenuOpen(state.isOpen)
    if (!state.isOpen) {
      setShowAttributes(false)
      setShowDescription(false)
      setShowStoreLocations(false)
    }
  }

  return (
    <>
      <Menu
        right
        width={ appProps.currentDevice == 'desktop' ? '50%' : '100%' }
        isOpen={ menuOpen }
        className={ 'product-burger-menu' }
        onStateChange={ (state) => handleStateChange(state) }
        customBurgerIcon={ false }
        customCrossIcon={ <i className="fa-light fa-xmark" /> }
        overlayClassName={ 'product-burger-menu-overlay' }
      >
        <>
          <div onClick={ () => setMenuOpen(false) }
               className='pointer close_icon flex-box items-center content-center' />
          <div id='product_page_above_description'></div>
          { showDescription &&
            <div className="section description">
              <div className="title">
                { appProps.translations.products.description }
              </div>
              <ProductDescription
                description={ productDescription }
              />
            </div>
          }
          <div id='product_page_above_product_specs'></div>
          { showAttributes &&
            <div className="section attributes">
              <div className="title">
                { appProps.translations.products.attributes }
              </div>
              <Attributes
                appProps={ appProps }
                product={ product }
                category={ category }
              />
            </div>
          }

          { showStoreLocations &&
            <div className="section store_locations">
              <div className="title">
                { appProps.translations.products.store_availability }
              </div>
              <StoreLocations
                appProps={ appProps }
                storeLocations={ product.storeLocations }
              />
            </div>
          }
        </>
      </Menu>
    </>
  )
}
