import React from "react"
import Select from "react-select"
import { customReactSelectStyles, customReactSelectTheme, getTranslatableField, truncateString, numberValidator } from "../../../helpers/utils"
import ErrorMessage from '../helpers/ErrorMessage'
import { scrollToStep } from '../helpers/scroller'
import { checkoutDeliveryMethodAnalytics } from '../../../helpers/analytics'
import { checkoutDeliveryMethodLegacyAnalytics } from '../../../helpers/legacy_analytics'

export default class DeliveryStep extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      available_countries: [],
      available_states: [],
      states_required: false,
      availableStores: []
    }
  }

  componentDidMount(){
    this.props.toggleShippingStepVisibility(this.props.deliveryOption === 'delivery')
    scrollToStep("delivery-step")

    if( this.props.country || this.props.area ){
      Rails.ajax({
        type: "GET",
        url: this.props.adminOrderNumber ? `/admin/${ReactiveCore.locale}/checkout/${this.props.adminOrderNumber}/get_selected?numeric_code=${this.props.country}&region_code=${this.props.area}` : `/checkout/get_selected?numeric_code=${this.props.country}&region_code=${this.props.area}`,
        dataType: "json",
        success: res => {
          this.setState(res)
          if(res.selectedCountry) {
            this.setState({states_required: res.selectedCountry.states_required})
          }
        }
      })
    }

    if (this.props.deliveryOption === 'pickup') {
      this.getAvailableStores()
      this.props.updateStep('deliveryOption', 'pickup')
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.deliveryOption === 'pickup' && this.props.deliveryOption !== prevProps.deliveryOption) {
      this.getAvailableStores()
    }
  }

  getAvailableCountries(){
    if (this.state.available_countries.length === 0){
      Rails.ajax({
        type: "GET",
        url: `/${ this.props.appProps.currentLocale }/countries?fetch_delivery_zones=true`,
        dataType: "json",
        success: res => {
          this.setState(res)
        }
      })
    }
  }

  getAvailableStates(selectedCountry){
    if (this.state.available_states.length === 0){
      Rails.ajax({
        type: "GET",
        url: `/${ this.props.appProps.currentLocale }/countries/${ selectedCountry }/states`,
        dataType: "json",
        success: res => {
          this.setState(res)
        }
      })
    }
  }

  getAvailableStores() {
    Rails.ajax({
      type: 'GET',
      url: '/checkout/get_pickup_stores',
      dataType: 'json',
      success: res => {
        if (res.availableStores.length === 1 && !this.props.storeId) {
          this.props.updateStep('storeId', res.availableStores[0].id)
        }

        this.setState(res)
      }
    })
  }

  handleCountryChange(selected) {
    this.setState({available_states: selected.states, states_required: selected.states.length > 0, selectedState: null})
    this.props.updateStep("country", selected.value)
    this.props.updateStep("countryAlpha2Code", selected.alpha2_code)
    this.props.updateStep("area", '')
    this.props.updateStep("stateLabel", '')
  }

  changeDeliveryOption(){
    const { deliveryOption, options, updateStep, toggleShippingStepVisibility } = this.props

    if( options.indexOf(deliveryOption) === -1 ) return

    const value = deliveryOption === options[0] ? options[1] : options[0]

    toggleShippingStepVisibility(value === "delivery")

    updateStep("deliveryOption", value)
  }

  handleClick(){
    const {
      deliveryOption, proceedToNextStep, fullName, phone, mobile, country,
      address, city, postalCode, area, googleAnalyticsId, googleMeasurementId,
      storeId
    } = this.props
    let noErrors = true

    document.querySelectorAll('[id$="-error"]').forEach(errorElement => errorElement.remove())
    document.querySelectorAll('input').forEach(element => element.style.borderColor = '')

    if( fullName.length === 0 ){
      ErrorMessage("delivery-full-name", "red", "delivery-full-name-error", `${this.props.translations.checkout.general_error} ${this.props.translations.checkout.full_name}`, "red")
      noErrors = false
    }

    if( phone.trim().length > 0 && !numberValidator(phone.trim()) ) {
      ErrorMessage("delivery-phone", "red", "delivery-phone-error", `${this.props.translations.checkout.phone_detailed_error}`, "red")
      noErrors = false
      scrollToStep("delivery-step")
    }

    if( phone.trim().length === 0 ) {
      ErrorMessage("delivery-phone", "red", "delivery-phone-error", `${this.props.translations.checkout.general_error} ${this.props.translations.checkout.phone}`, "red")
      noErrors = false
    }

    if( mobile.trim().length > 0 && !numberValidator(mobile.trim()) ) {
      ErrorMessage("delivery-mobile-phone", "red", "delivery-mobile-phone-error", `${this.props.translations.checkout.phone_detailed_error}`, "red")
      noErrors = false
      scrollToStep("delivery-step")
    }

    if( this.props.requireMobilePhone && mobile.trim().length === 0 ) {
      ErrorMessage("delivery-mobile-phone", "red", "delivery-mobile-phone-error", `${this.props.translations.checkout.general_error} ${this.props.translations.checkout.mobile_phone}`, "red")
      noErrors = false
    }

    if (deliveryOption === 'pickup' && !storeId) {
      ErrorMessage('pickup-store', 'red', 'pickup-store-error', `${ this.props.translations.checkout.general_error }  ${ this.props.translations.checkout.pickup_store }`, 'red')
      noErrors = false
    }

    if( deliveryOption === 'delivery'){
      if( !address || address.length === 0 ){
        ErrorMessage("delivery-address", "red", "delivery-address-error", `${this.props.translations.checkout.general_error} ${this.props.translations.checkout.address}`, "red")
        noErrors = false
      }
      if( !city || city.length === 0 ){
        ErrorMessage("delivery-city", "red", "delivery-city-error", `${this.props.translations.checkout.general_error} ${this.props.translations.checkout.city}`, "red")
        noErrors = false
      }
      if( !country || country.length === 0 ){
        ErrorMessage("delivery-country", "red", "delivery-country-error", `${this.props.translations.checkout.general_error} ${this.props.translations.checkout.country}`, "red")
        noErrors = false
      }
      if( !postalCode || postalCode.length === 0 ){
        ErrorMessage("delivery-postal_code", "red", "delivery-postal_code-error", `${this.props.translations.checkout.general_error} ${this.props.translations.checkout.postal_code}`, "red")
        noErrors = false
      }
      if( this.state.states_required && (!area || area.length === 0) ){
        ErrorMessage("delivery-area", "red", "delivery-area-error", `${this.props.translations.checkout.general_error} ${this.props.translations.checkout.state}`, "red")
        noErrors = false
      }
    }

    if (noErrors) {
      this.props.googleMeasurementId ? checkoutDeliveryMethodAnalytics(deliveryOption) : checkoutDeliveryMethodLegacyAnalytics(deliveryOption);
      return proceedToNextStep()
    } else {
      return null
    }

  }

  render(){
    const {
      options, deliveryOption, fullName, phone, mobile, country, address, city,
      postalCode, area, orderNotes, storeId, currentLocale
    } = this.props

    const storeOptions = deliveryOption === 'pickup' ?
      this.state.availableStores.map( store => ({
        value: store.id,
        label: truncateString(`${ getTranslatableField(store, 'title', currentLocale, true) }${ (store.address && store.address.formatted_address) ? (', ' + store.address.formatted_address) : '' }`, 40)
      })) : []

    return(
      <div id="delivery-step" className="checkout-step delivery-details card">
        <div className="step-title">
          { this.props.translations.checkout.delivery_step }
        </div>

        { options.length > 1 &&
          <React.Fragment>
            <div className='step-subtitle'>
              { this.props.translations.checkout.choose_delivery_method }
            </div>

            <div className="delivery-method">
              <div
                className={ `tile delivery delivery-icon ${ deliveryOption === 'delivery' ? 'selected' : ''}` }
                onClick={(e) => { !e.target.classList.contains('selected') && this.changeDeliveryOption() }}
              >
                { this.props.translations.checkout.method_delivery }
              </div>
              <div
                className={ `tile pickup pickup-icon ${ deliveryOption === 'pickup' ? 'selected' : ''}` }
                onClick={(e) => { !e.target.classList.contains('selected') && this.changeDeliveryOption() }}
              >
                { this.props.translations.checkout.method_pickup }
              </div>
            </div>
          </React.Fragment>
        }

        <div className="fields">
          <div className='step-subtitle'>
            { this.props.translations.checkout[deliveryOption === 'delivery' ? 'enter_delivery_details' : 'enter_contact_details'] }
          </div>

          <div className="field-columns">
            <div className="field">
              <div className='flex-box items-center'>
                <label>
                  { this.props.translations.checkout.full_name }
                </label>
                <div style={{marginLeft: 5}}>*</div>
              </div>
              <input
                id="delivery-full-name"
                type="text"
                value={ fullName }
                onChange={ (e)=> { this.props.updateStep("fullName", e.target.value) } }
              />
            </div>

            { deliveryOption === 'pickup' &&
              <div className='field'>
                <div className='flex-box items-center'>
                  <label>
                    { this.props.translations.checkout.pickup_store }
                  </label>
                  <div style={{ marginLeft: 5 }}>*</div>
                </div>
                <Select
                  id='pickup-store'
                  theme={ customReactSelectTheme }
                  styles={ customReactSelectStyles }
                  isSearchable={ false }
                  options={ storeOptions }
                  value={ storeOptions.find( store => store.value === storeId ) }
                  onChange={ option => this.props.updateStep('storeId', option.value) }
                />
              </div>
            }
          </div>

          <div className='field-columns'>
            <div className="field">
              <div className='flex-box items-center'>
                <label>
                  { this.props.translations.checkout.phone }
                </label>
                <div style={{marginLeft: 5}}>*</div>
              </div>
              <input
                id="delivery-phone"
                type="text"
                value={ phone }
                onChange={ (e)=> { this.props.updateStep("phone", e.target.value)} }
              />
            </div>

            <div className="field">
              <div className='flex-box items-center'>
                <label>
                  { this.props.translations.checkout.mobile_phone }
                </label>
                { this.props.requireMobilePhone && <div style={{marginLeft: 5}}>*</div> }
              </div>
              <input
                id="delivery-mobile-phone"
                type="text"
                value={ mobile }
                onChange={ (e)=> { this.props.updateStep("mobile", e.target.value)} }
              />
            </div>
          </div>

          { deliveryOption === 'delivery' &&
            <React.Fragment>
              <div className="field-columns">
                <div className="field">
                  <div className='flex-box items-center'>
                    <label>
                    { this.props.translations.checkout.address }
                    </label>
                    <div style={{marginLeft: 5}}>*</div>
                  </div>
                  <input
                    id="delivery-address"
                    type="text"
                    value={ address }
                    onChange={ (e)=> { this.props.updateStep("address", e.target.value) } }
                  />
                </div>

                <div className="field">
                  <div className='flex-box items-center'>
                    <label>
                      { this.props.translations.checkout.city }
                    </label>
                    <div style={{marginLeft: 5}}>*</div>
                  </div>
                  <input id="delivery-city"
                    type="text"
                    value={ city }
                    onChange={ (e)=> { this.props.updateStep("city", e.target.value) } }
                  />
                </div>
              </div>

              <div className="field-columns">
                <div className="field">
                  <div className='flex-box items-center'>
                    <label>
                      { this.props.translations.checkout.postal_code  }
                    </label>
                    <div style={{marginLeft: 5}}>*</div>
                  </div>
                  <input id="delivery-postal_code"
                    type="text"
                    value={ postalCode}
                    onChange={ (e)=> { this.props.updateStep("postalCode", e.target.value) } }
                  />
                </div>
              </div>

              <div className="field-columns">
                <div className="field">
                  <div className='flex-box items-center'>
                    <label>
                      { this.props.translations.checkout.country }
                    </label>
                    <div style={{marginLeft: 5}}>*</div>
                  </div>
                  <Select
                    id="delivery-country"
                    theme={ customReactSelectTheme }
                    styles={ customReactSelectStyles }
                    isSearchable={ false }
                    options={ this.state.available_countries }
                    value={ this.state.available_countries.length === 0 ? this.state.selectedCountry : this.state.available_countries.filter(c => c.value === country) }
                    onChange={ (deliveryOption) => { this.handleCountryChange(deliveryOption) } }
                    onMenuOpen={ () => { this.getAvailableCountries() } }
                  />
                </div>

                {this.state.states_required &&
                  <div className="field">
                    <div className='flex-box items-center'>
                      <label>
                      { this.props.translations.checkout.state }
                      </label>
                      <div style={{marginLeft: 5}}>*</div>
                    </div>
                    <Select
                      id="delivery-area"
                      theme={ customReactSelectTheme }
                      styles={ customReactSelectStyles }
                      isSearchable={ false }
                      options={ this.state.available_states }
                      value={ this.state.available_states.length > 0 && area.length > 0 ? this.state.available_states.filter(s => s.value === area) : this.state.selectedState }
                      onChange={ (option) => { this.props.updateStep("area", option.value); this.props.updateStep("stateLabel", option.name) } }
                      onMenuOpen={ () => { this.getAvailableStates(country) } }
                    />
                  </div>
                }
              </div>
            </React.Fragment>
          }

          <div className="field notes">
            <label>
              { this.props.translations.checkout.order_notes }
            </label>
            <textarea value={ orderNotes} onChange={ (e)=> { this.props.updateStep("orderNotes", e.target.value) } }/>
          </div>

          <div className="field button-wrapper">
            <a className="button" onClick={ () => { this.handleClick() } }>
              { this.props.translations.checkout.continue_button }
            </a>
          </div>

        </div>
      </div>
    )
  }
}
