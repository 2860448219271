import React from "react"
import { getTranslatableField } from "../../helpers/utils"

export default function Attributes({ appProps, product, category }) {

  function attributeRenderer(attribute) {
    const prototypeAttribute = category.attributes.find(attr => attr.id === attribute.prototypeAttributeId)
    const title = getTranslatableField(prototypeAttribute, 'title', appProps.currentLocale, false)
    const value = getTranslatableField(attribute, 'title', appProps.currentLocale, false)

    if (value) {
      return (
        <ul className="product-attributes">
          <li className="product-attribute">
            <div className="name">{ title }</div>
          </li>
          <li className="product-attribute">
            <div className="value">{ value }</div>
          </li>
        </ul>
      )
    }

    return <></>
  }

  if (category && category.groups.length > 0) {
    return (
      category.groups.map((group, index) => {
        const groupTitle = getTranslatableField(group, 'title', appProps.currentLocale, false)
        let groupAttributes = product.attributes.filter(attribute => attribute.prototypeAttributeGroupId === group.id && getTranslatableField(attribute, 'title', appProps.currentLocale, false))

        if (groupAttributes.length > 0) {
          return (
            <React.Fragment key={ index }>
              { groupTitle && groupTitle !== '' && groupAttributes.length > 0 &&
                <div style={{ marginTop: 20, marginBottom: 10, fontWeight: 700 }}>{ groupTitle }</div> 
              }
              { groupAttributes.map((attribute, attrIndex) => {
                  return (
                    <React.Fragment key={ `${ index }_${ attrIndex }` }>
                      { attributeRenderer(attribute) }
                    </React.Fragment>
                  )
                })
              }
            </React.Fragment>
          )
        } else {
          return <React.Fragment key={ index }></React.Fragment>
        }
      })
    )
  }

  return (
    <div>
      { product.attributes.map((attribute, index) => {
          return (
            <React.Fragment key={index}>
              { attributeRenderer(attribute) }
            </React.Fragment>
          )
        })
      }
    </div>
  )
}
